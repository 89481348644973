import { Controller } from '@hotwired/stimulus'
import { useClickOutside, useTransition } from 'stimulus-use'
import { computePosition, autoUpdate } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ['menu']

  static values = {
    strategy: {
      type: String,
      default: 'fixed',
    },
    placement: {
      type: String,
      default: 'bottom-end',
    },
    enterActive: {
      type: String,
      default: 'transition ease-out duration-100',
    },
    enterFrom: {
      type: String,
      default: 'transform opacity-0 scale-95',
    },
    enterTo: {
      type: String,
      default: 'transform opacity-100 scale-100',
    },
    leaveActive: {
      type: String,
      default: 'transition ease-in duration-75',
    },
    leaveFrom: {
      type: String,
      default: 'transform opacity-100 scale-100',
    },
    leaveTo: {
      type: String,
      default: 'transform opacity-0 scale-95',
    },
  }

  connect() {
    useClickOutside(this, {
      element: this.element,
    })

    useTransition(this, {
      element: this.menuTarget,
      enterActive: this.enterActiveValue,
      enterFrom: this.enterFromValue,
      enterTo: this.enterToValue,
      leaveActive: this.leaveActiveValue,
      leaveFrom: this.leaveFromValue,
      leaveTo: this.leaveToValue,
    })

    document.addEventListener('keyup', this.keyup)

    this.autoPosition = autoUpdate(this.element, this.menuTarget, this.autoUpdate)
  }

  autoUpdate = async () => {
    const position = await computePosition(this.element, this.menuTarget, {

      strategy: this.strategyValue,
      placement: this.placementValue,
    })

    this.menuTarget.style.left = position.x + 'px'
    this.menuTarget.style.top = position.y + 'px'
    this.menuTarget.style.position = position.strategy
  }

  disconnect() {
    this.autoPosition()

    document.removeEventListener('keyup', this.keyup)
  }

  open() {
    this.enter()
  }

  close() {
    this.leave()
  }

  clickOutside() {
    this.close()
  }

  toggle() {
    this.toggleTransition()
  }

  keyup = (event) => {
    if (event.key == 'Escape') {
      this.close()
    }
  }

}
