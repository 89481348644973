import { Controller } from "@hotwired/stimulus"
import * as pbi from 'powerbi-client'

export default class extends Controller {
  static values = {
    reportId: String,
    embedUrl: String,
    accessToken: String
  }

  connect() {
    const config = {
      type: "report",
      tokenType: pbi.models.TokenType.Embed,
      id: this.reportIdValue,
      embedUrl: this.embedUrlValue,
      accessToken: this.accessTokenValue,
    }

    window.powerbi.embed(this.element, config)
  }
}
