import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['container', 'sidebar', 'backdrop']

  connect() {
    useClickOutside(this, {
      element: this.sidebarTarget,
    })
  }

  enter() {
    this.containerTarget.classList.remove('hidden')
  }

  leave() {
    this.containerTarget.classList.add('hidden')
  }

  open() {
    this.enter()
  }

  close() {
    this.leave()
  }

  clickOutside() {
    this.close()
  }
}
