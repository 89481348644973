import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "code",
  ]

  static values = {
    clientId: String,
  }

  connect() {
    this.authorize()
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  async authorize() {
    const body = {
      client_id: this.clientIdValue
    }

    const { user_code, device_code, interval } = await this.post('/oauth/authorize_device', body)

    this.device_code = device_code
    this.user_code = user_code
    this.interval = interval
    this.timeout = setTimeout(this.token.bind(this), this.interval * 1000)

    this.codeTarget.textContent = this.user_code.trim()
  }

  async token() {
    const body = {
      client_id: this.clientIdValue,
      device_code: this.device_code,
      grant_type: 'urn:ietf:params:oauth:grant-type:device_code'
    }

    const { error, resource_owner_id } = await this.post('/oauth/token', body)

    if (resource_owner_id) {
      window.location.href = `/tv/${resource_owner_id}`

      return
    }

    if ('authorization_pending' == error) {
      this.timeout = setTimeout(this.token.bind(this), this.interval * 1000)
    }

    if ('slow_down' == error) {
      this.interval += 5
      this.timeout = setTimeout(this.token.bind(this), this.interval * 1000)
    }

    if ('expired_token' == error) {
      await this.authorize()
    }
  }

  async post(url, data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
    })

    return await response.json()
  }
}
