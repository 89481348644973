import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "bar",
  ]

  static values = {
    duration: Number,
    remaining: Number
  }

  connect() {
    this.barTarget.style.width = (this.remainingValue / this.durationValue * 100) + "%"
    this.barTarget.style.transitionDuration = `${this.remainingValue}s`

    requestAnimationFrame(() => {
      this.barTarget.style.width = "0%"
    })
  }
}
