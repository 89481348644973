import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.showModal()

    document.addEventListener("keyup", this.keyup)
  }

  disconnect() {
    document.removeEventListener("keyup", this.keyup)
  }

  clickOutside(e) {
    if (e.target === this.element) {
      this.close()
    }
  }

  close() {
    this.element.remove()
  }

  keyup = (event) => {
    if (event.key == "Escape") {
      this.close()
    }
  }
}
