import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
    duration: Number,
    url: String
  }

  connect() {
    setTimeout(this.redirect, this.durationValue * 1000)

    document.addEventListener('turbo:frame-missing', this.reload)
    document.addEventListener('turbo:fetch-request-error', this.retry)
  }

  disconnect() {
    document.removeEventListener('turbo:frame-missing', this.reload)
  }

  reload() {
    window.location.reload()
  }

  retry(e) {
    setTimeout(this.redirect, 5000)

    e.preventDefault()
  }

  redirect = () => {
    Turbo.visit(this.urlValue, {
      action: "replace",
      frame: "slide"
    })
  }
}
