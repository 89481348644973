import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    interval: {
      type: Number,
      default: 300 * 1000,
    }
  }

  connect() {
    this.interval = setTimeout(this.reload, this.intervalValue)
  }

  reload = () => {
    this.element.reload()

    this.timeout = setTimeout(this.reload, this.intervalValue)
  }
}
