import { Controller } from "@hotwired/stimulus"
import Marquee from 'vanilla-marquee'

export default class extends Controller {
  connect() {
    // When the page is morphoed the connect() method is called again.
    // This is bad since it would load the RSS feed again and again and append yet another
    // marquee to the page. This is a quick fix to prevent that from happening.
    if (this.marquee) {
      //this.marquee.updateText(this.element.textContent)

      return
    }

    this.marquee = new Marquee(this.element, {
      speed: 150,
    })
  }

  disconnect() {
    this.marquee.destroy()
  }
}
