import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["form", "template"]

  add(event) {
    event.preventDefault()

    const name = event.target.getAttribute('data-template-name')

    const template = this.templateTargets.find(function (template) {
      return template.getAttribute('data-template-name') === name
    })

    if (template) {
      const content = template.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

      this.formTarget.insertAdjacentHTML("beforeend", content)
    }
  }

  remove(event) {
    event.preventDefault()

    const fieldset = event.target.closest("fieldset")

    fieldset.querySelector("input[name*='_destroy']").value = 1

    fieldset.style.display = "none"
  }
}
