import { application } from "./application"
import Clipboard from '@stimulus-components/clipboard'
import Sortable from '@stimulus-components/sortable'
import DeviceAuthorization from './device_authorization_controller'
import Dropdown from './dropdown_controller'
import Flash from './flash_controller'
import Marquee from './marquee_controller'
import Modal from './modal_controller'
import NestedForm from './nested_form_controller'
import PowerBi from './power_bi_controller'
import ProgressBar from './progress_bar_controller'
import Reloading from './reloading_controller'
import Sidebar from './sidebar_controller'
import Slide from './slide_controller'

application.register("clipboard", Clipboard)
application.register("device-authorization", DeviceAuthorization)
application.register("dropdown", Dropdown)
application.register("flash", Flash)
application.register("marquee", Marquee)
application.register("modal", Modal)
application.register("nested-form", NestedForm)
application.register("power-bi", PowerBi)
application.register("progress-bar", ProgressBar)
application.register("reloading", Reloading)
application.register("sidebar", Sidebar)
application.register("slide", Slide)
application.register("sortable", Sortable)
