import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static values = {
    timeout: {
      type: Number,
      default: 5
    }
  }

  connect() {
    useTransition(this, {
      element: this.element,
      enterActive: "transform ease-out duration-300 transition",
      enterFrom: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
      enterTo: "translate-y-0 opacity-100 sm:translate-x-0",
      leaveActive: "transition ease-in duration-100",
      leaveFrom: "opacity-100",
      leaveTo: "opacity-0",
      leaveAfter: this.timeoutValue * 1000,
    })

    this.enter()
  }
}
